@import url(https://fonts.googleapis.com/css?family=Roboto|Rubik&display=swap);
body {
  background: #fcfcff;
  color: #212529;
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 0;
}

.btn-group {
  display: inline;
  display: initial;
}

